body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: rgba(0, 21, 41, 0.95);
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  line-height: 62px;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: #fff;
}
.header0-menu > .ant-menu a:hover {
  color: #1890ff;
}
.header0 .ant-menu-item-selected a {
  color: #1890ff;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: #001529;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #fff;
  }
  .header0 .ant-menu a:hover {
    color: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #fff;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  -webkit-transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.parent2 {
  display: inline-block;
  border: 1px solid #ffffff;
  overflow: hidden;
  border-radius: 4px;
  padding: 0 5px;
  position: relative;
}
.qrcode {
  display: block;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 1px;
  left: 1px;
  object-fit: cover;
  z-index: 999;
  opacity: 0;
}
.left1 {
  width: 20px;
  height: 60px;
  float: left;
  margin-top: -2px;
  margin-left: 10px;
}
.left2 {
  width: 100px;
  height: 60px;
  float: left;
  margin-right: 20px;
}
.right2 {
  width: 20px;
  height: 60px;
  float: left;
  margin-top: -2px;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.pricing0-wrapper .pricing0 {
  min-height: 370px;
  padding: 0 24px;
  display: flex;
  align-items: flex-end;
}
.pricing0-wrapper .pricing0-img-wrapper {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
}
.pricing0-wrapper .pricing0-img-wrapper .pricing0-img {
  display: block;
  width: 100%;
  max-width: 560px;
}
.pricing0-wrapper .pricing0-img-wrapper .pricing0-img img {
  display: block;
}
.pricing0-wrapper .pricing0-text-wrapper {
  min-height: 320px;
  padding: 0 40px;
  max-width: 560px;
  margin-bottom: 32px;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-content,
.pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
  position: relative !important;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
  font-size: 24px;
  font-weight: normal;
  color: #404040;
  margin: 72px auto 16px;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-content {
  font-size: 12px;
  color: #666;
  line-height: 1.5;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-pricing {
  font-size: 36px;
  color: #404040;
  margin: 32px 0 24px;
}
.qrcode {
  display: block;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 1px;
  left: 1px;
  object-fit: cover;
  z-index: 999;
  opacity: 0;
}
@media screen and (max-width: 767px) {
  .pricing0-wrapper {
    min-height: 720px;
  }
  .pricing0-wrapper .pricing0 {
    display: block;
  }
  .pricing0-wrapper .pricing0-img-wrapper {
    padding: 0;
    text-align: center;
    margin-top: 24px;
  }
  .pricing0-wrapper .pricing0-img-wrapper .pricing0-img {
    display: inline-block;
    width: 80%;
    margin: auto;
  }
  .pricing0-wrapper .pricing0-text-wrapper {
    height: auto;
    text-align: center;
    padding: 0;
    max-width: 100%;
  }
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-content,
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
    width: 100%;
    top: auto;
  }
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.box {
  width: 150px;
  height: 80px;
  border-radius: 4px;
  opacity: 0;
  position: relative;
  top: -50px;
}
.box:hover {
  opacity: 1;
}
.boximage {
  width: 150px;
  height: 150px;
}
@media screen and (max-width: 767px) {
  .box {
    width: 150px;
    height: 50px;
    border-radius: 4px;
    opacity: 0;
    position: relative;
    top: -50px;
    margin-left: auto;
    margin-right: auto;
  }
  .box:hover {
    width: 150px;
    height: 150px;
    position: relative;
    opacity: 1;
  }
  .boximage {
    width: 150px;
    height: 150px;
  }
}
.content8-wrapper.home-page-wrapper {
  overflow: visible;
  overflow: initial;
  width: calc(100% - 112px);
  min-height: 878px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 32px 32px rgba(34, 94, 222, 0.08);
  background: #fff;
}
.content8-wrapper.home-page-wrapper .home-page {
  margin-top: -220px;
  padding: 64px 24px;
  overflow: hidden;
}
.content8-wrapper .content-wrapper {
  margin-top: 72px;
}
.content8-wrapper .content-wrapper .content8-block-wrapper {
  margin-bottom: 60px;
}
.content8-wrapper .content-wrapper .content8-block {
  width: 100%;
  max-width: 192px;
  margin: auto;
}
.content8-wrapper .content-wrapper .content8-img {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.content8-wrapper .content-wrapper .content8-img img {
  width: 100%;
  display: block;
}
.content8-wrapper .content-wrapper .content8-img img[src=""] {
  background: -webkit-gradient(linear, left bottom, right top, from(#d6defc), to(#fff));
  background: linear-gradient(to top right, #d6defc, #fff);
  padding-bottom: 100%;
}
.content8-wrapper .content-wrapper .content8-title {
  font-size: 16px;
  color: #0d1a26;
  font-weight: 400;
  margin: 24px auto 8px;
  text-align: center;
  white-space: nowrap;
}
.content8-wrapper .content-wrapper .content8-content {
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  color: #697b8c;
}
@media screen and (max-width: 767px) {
  .content8-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
  }
  .content8-wrapper.home-page-wrapper .content8.home-page {
    margin: auto;
    padding-bottom: 0;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-block {
    max-width: 240px;
    margin-bottom: 30px;
  }
}
.footer2-wrapper {
  background-color: #0d1a26;
  height: 80px;
  overflow: hidden;
}
.footer2-wrapper .footer2 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
.footer2-wrapper .copyright {
  float: right;
}
.footer2-wrapper .copyright > * {
  display: inline-block;
}
.footer2-wrapper .copyright-logo {
  width: 16px;
  margin-right: 8px;
}
.footer2-wrapper .copyright-logo img {
  width: 100%;
}
.footer2-wrapper .copyright-line {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .links {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.footer2-wrapper .links a {
  height: 21px;
  display: inline-block;
  margin-right: 32px;
}
.footer2-wrapper .links a img {
  display: block;
}
@media screen and (max-width: 767px) {
  .footer2-wrapper .footer2 {
    font-size: 12px;
  }
  .footer2-wrapper .footer2.home-page {
    padding: 0;
  }
  .footer2-wrapper .footer2 > div {
    width: 90%;
    margin: auto;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
.banner0 > .banner0-text-wrapper > .jxoc5sc064-editor_css {
  text-align: left;
  margin: 0px 0px 20px;
}
.templates-wrapper > .banner0 > .jxoc6mvv77g-editor_css {
  width: 750px;
}
.home-page-wrapper > .footer1 > .jxocs5m5n2-editor_css {
  cursor: null;
}
div > .templates-wrapper > .jxocvzr0pf-editor_css {
  color: #00a2ae;
}
.banner0 > .banner0-text-wrapper > .jxod7fbpcrf-editor_css {
  color: #f30e0e;
}
.ant-menu > .ant-menu-item > .jxocwaxbre-editor_css {
  font-weight: bold;
  color: #00bfbf;
  text-align: left;
  background-color: rgba(243, 0, 0, 0);
}
.ant-menu > .ant-menu-item > .jxocw9m2ykr-editor_css {
  color: #3f3f3f;
}
.templates-wrapper > .header0 > .jxoc9w9ljz7-editor_css {
  background-color: rgba(255, 255, 255, 0);
  background-repeat: repeat-x;
  background-position: center;
  background-size: 100%;
  background-attachment: fixed;
  margin: auto auto -100px;
}
div > .templates-wrapper > .jxocq9mh0e-editor_css {
  margin: 100px 0 0;
}
.templates-wrapper > .header0 > .jxodhly9z17-editor_css {
  color: rgba(49, 70, 89, 0);
  overflow: hidden;
  background-attachment: scroll;
}
div > .templates-wrapper > .jxodisnq1ai-editor_css {
  position: fixed;
  background-color: rgba(255, 255, 255, 0);
}
.home-page > .banner2-text-wrapper > .jxodvpkyru-editor_css {
  width: 200px;
  height: 50px;
  background-color: #00a2ae;
}
.banner-anim > .banner-anim-elem > .jxodll1yjcf-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/501102041.jpg);
}
div > .templates-wrapper > .jxodlvy1x9m-editor_css {
  color: rgba(255, 255, 255, 0);
  position: absolute;
  background-color: rgba(0, 21, 41, 0);
  box-shadow: none;
}
.banner-anim-elem > .home-page > .jxodw054rpn-editor_css {
  width: 50%;
  position: relative;
  left: 10%;
}
.home-page > .banner2-text-wrapper > .jxoeqdugc76-editor_css {
  position: relative;
}
.banner-anim > .banner-anim-elem > .jxodntepwcp-editor_css {
  top: auto;
}
.home-page > .banner2-text-wrapper > .jxodprz6epo-editor_css {
  font-size: 50px;
  line-height: 1em;
  background-attachment: fixed;
}
.home-page > .banner2-text-wrapper > .jxodprz6epo-editor_css:hover {
  color: #108ee9;
}
@media screen and (max-width: 767px) {
  .home-page > .banner2-text-wrapper > .jxodprz6epo-editor_css {
    line-height: 1em;
    width: 100%;
    position: static;
    background-attachment: fixed;
  }
}
.banner3 > .banner3-text-wrapper > .jxof3fcg82s-editor_css {
  font-weight: lighter;
}
div > .templates-wrapper > .jxof2guvi96-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/501102041.jpg);
}
.banner3 > .banner3-text-wrapper > .jxof1vkoxr-editor_css {
  font-weight: 400;
  background-attachment: scroll;
}
@media screen and (max-width: 767px) {
  .banner3 > .banner3-text-wrapper > .jxof1vkoxr-editor_css {
    font-size: 26px;
    font-weight: bold;
  }
}
.templates-wrapper > .banner3 > .jxof59d06lb-editor_css {
  height: 460px;
  top: 30%;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .banner3 > .jxof59d06lb-editor_css {
    width: 95%;
    height: 460px;
    position: relative;
    top: 30%;
  }
}
.header0 > .home-page > .jxodljf52sb-editor_css {
  width: 130px;
}
@media screen and (max-width: 767px) {
  .header0 > .home-page > .jxodljf52sb-editor_css {
    width: 80px;
  }
}
.ant-row > .ant-col > .jxofl1suvja-editor_css {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxofl1suvja-editor_css {
    width: 100%;
    height: 240px;
    position: relative;
  }
}
div > .templates-wrapper > .jxog00tng6-editor_css {
  height: 520px;
}
.home-page-wrapper > .ant-row > .jxofu4u0l4c-editor_css {
  height: 450px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .ant-row > .jxofu4u0l4c-editor_css {
    height: 250px;
    margin: 0px 0px;
  }
}
.home-page-wrapper > .ant-row > .jxofl0jryef-editor_css {
  padding: 0px 32px 0px 0px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .ant-row > .jxofl0jryef-editor_css {
    padding: 0px 0px;
  }
}
.ant-row > .ant-col > .jxog0hehvin-editor_css {
  font-size: 36px;
  font-weight: bold;
  line-height: 2;
  color: #353535;
  min-width: 1px;
  margin: 80px 0px 0.5em;
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxog0hehvin-editor_css {
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    color: #353535;
    min-width: 1px;
    margin: 10px 0px 0.5em;
  }
}
.templates-wrapper > .home-page-wrapper > .jxofw3q75w-editor_css {
  line-height: 2em;
  height: 450px;
  margin: 80px auto auto;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .home-page-wrapper > .jxofw3q75w-editor_css {
    line-height: 2em;
    height: 750px;
    margin: 20px auto auto;
  }
}
.ant-row > .ant-col > .jxog0wahfrn-editor_css {
  font-size: 16px;
  line-height: 2;
  color: #353535;
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxog0wahfrn-editor_css {
    font-size: 16px;
    font-weight: lighter;
    line-height: 1.5;
    color: #353535;
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .header0 > .home-page > .jxpjefrz9io-editor_css {
    text-align: right;
    background-color: #f04134;
  }
  .header0 > .home-page > .jxpjefrz9io-editor_css:active {
    background-color: #ff0000;
  }
  .header0 > .home-page > .jxpjefrz9io-editor_css:focus {
    background-color: #ff0707;
  }
}
.ant-col > .jxpklu9drag-editor_css > .jxpklhxf47k-editor_css {
  line-height: 38px;
  height: 40px;
}
.ant-col > div > .jxpklhxf47k-editor_css {
  line-height: 43px;
  color: #00a29a;
  width: 150px;
  height: 45px;
  border-color: #00a29a;
}
.ant-row > .ant-col > .jxpjevgd316-editor_css {
  margin: 0 0 10px;
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxpjevgd316-editor_css {
    width: 100%;
    margin: 0px 0px 10px;
  }
}
.templates-wrapper > .home-page-wrapper > .jxpkgua6sq-editor_css {
  min-height: 375px;
  margin: 80px auto auto;
  padding: 0px 24px 10px;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .home-page-wrapper > .jxpkgua6sq-editor_css {
    min-height: 375px;
    margin: 30px auto auto;
    padding: 0px 24px 10px;
  }
}
.ant-col > .jxpkunzotz9-editor_css > .jxpklhxf47k-editor_css {
  color: #ffffff;
  background-color: #00a29a;
}
.templates-wrapper > .banner0 > .jxojdzhi8bn-editor_css {
  width: 100%;
  height: 100%;
  top: 35%;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .banner0 > .jxojdzhi8bn-editor_css {
    width: 100%;
    height: 60vh;
    top: 30vh;
  }
}
div > .templates-wrapper > .jxojc16h1z-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/homebanner0.jpg);
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jxojc16h1z-editor_css {
    height: 95vh;
    background-image: url(https://pic.hereamazing.com/webpic/images/homebanner0.jpg);
  }
}
.banner0 > .banner0-text-wrapper > .jxoje9sxbkp-editor_css {
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  padding: 0px 5px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .banner0 > .banner0-text-wrapper > .jxoje9sxbkp-editor_css {
    font-size: 16px;
    font-weight: 300;
    padding: 0px 5px;
    margin-top: 20px;
  }
}
.banner0 > .banner0-text-wrapper > .jxojbfnhvy-editor_css {
  font-size: 50px;
  line-height: 1.5;
  width: 90%;
  left: 0px;
  -webkit-transition: all 1000ms ease 0s;
  transition: all 1000ms ease 0s;
}
@media screen and (max-width: 767px) {
  .banner0 > .banner0-text-wrapper > .jxojbfnhvy-editor_css {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5;
    width: 90%;
    min-height: 30px;
    -webkit-transition: all 1000ms ease 0s;
    transition: all 1000ms ease 0s;
  }
}
.ant-row > .ant-col > .jxpkgrktuy-editor_css {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.5;
  color: #353535;
  margin: 52px auto 16px;
}
.ant-row > .ant-col > .jxpkht4ady-editor_css {
  font-size: 14px;
  font-weight: 300;
  line-height: 2;
  color: #888888;
}
.ant-row > .ant-col > .jxpo8so15v-editor_css {
  font-weight: 300;
  line-height: 2;
  color: #888888;
}
div > .templates-wrapper > .jxpoawea7o-editor_css {
  height: 100%;
  min-height: 375px;
}
.ant-row > .ant-col > .jxpo9abxym-editor_css {
  font-size: 36px;
  font-weight: bold;
  color: #353535;
  margin: 80px 0 0.5em;
}
.ant-row > .ant-col > .jxpoa06cgyd-editor_css {
  max-width: 560px;
  height: 12px;
  position: static;
}
.templates-wrapper > .home-page-wrapper > .jxpo5qj1bou-editor_css {
  height: 100%10;
  min-height: 375px;
}
.home-page-wrapper > .ant-row > .jxpkhhbixs-editor_css {
  padding: 0px 0px;
}
.home-page-wrapper > .ant-row > .jxpootbvhl-editor_css {
  max-width: 560px;
}
.home-page-wrapper > .ant-row > .jxpoovyttw-editor_css {
  max-width: 560px;
  padding: 0px 0px;
}
.ant-row > .ant-col > .jxpooo0bxec-editor_css {
  width: 100%;
  max-width: 560px;
}
div > .templates-wrapper > .jxpori14qfg-editor_css {
  margin: 50px 0px 0px;
}
.templates-wrapper > .home-page-wrapper > .jxpouhtsxis-editor_css {
  width: 100%;
  min-height: 375px;
}
.ant-row > .ant-col > .jxpp58jsysc-editor_css {
  width: 100%;
  max-width: 640px;
  position: relative;
}
.ant-row > .ant-col > .jxppeera6pq-editor_css {
  font-size: 36px;
  font-weight: bold;
  color: #353535;
}
.ant-row > .ant-col > .jxpperqlmk-editor_css {
  font-weight: 300;
  line-height: 2;
  color: #888888;
}
.home-page-wrapper > .ant-row > .jxpp5799ece-editor_css {
  max-width: 600px;
  padding: 0px 0px;
}
.home-page-wrapper > .ant-row > .jxpp55u5xrb-editor_css {
  max-width: 640px;
  left: 24px;
  padding: 0px 0px;
}
.templates-wrapper > .home-page-wrapper > .jxpp8swgtgl-editor_css {
  min-height: 375px;
}
div > .templates-wrapper > .jxppcc8nn-editor_css {
  height: 100%;
  min-height: 375px;
  top: 50px;
}
.home-page-wrapper > .ant-row > .jxppyccntmm-editor_css {
  padding: 0px 0px;
}
.ant-row > .ant-col > .jxpq0fojbz-editor_css {
  color: rgba(64, 64, 64, 0);
  margin: 0px 0px;
}
.ant-row > .ant-col > .jxpkgd4pbuj-editor_css {
  color: rgba(255, 255, 255, 0);
  margin: 0px 0px;
}
.home-page-wrapper > .ant-row > .jxpkpyk5ie9-editor_css {
  min-height: 350px;
}
.ant-col > .content5-block-content > .jxpqaknkbdf-editor_css {
  background-color: rgba(233, 233, 233, 0);
  background-attachment: scroll;
}
.ant-col > .content8-block > .jxpqqfcy16e-editor_css {
  color: #353535;
  margin: 12px auto 8px;
}
.ant-col > .content8-block > .jxpqqp6573c-editor_css {
  color: #353535;
  margin: 24px auto 0px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .home-page > .jxpqz5l3w6d-editor_css {
    position: relative;
    left: 30px;
  }
}
.ant-row > .ant-col > .jxpr9rrlbma-editor_css {
  width: 120px;
}
.home-page-wrapper > .footer1 > .jxprccpimcb-editor_css {
  text-align: left;
}
.templates-wrapper > .home-page-wrapper > .jxprenmq9y-editor_css {
  line-height: 50px;
  height: 80px;
}
div > .templates-wrapper > .jxprn4m83k-editor_css {
  background-color: #00a29a;
}
.home-page-wrapper > .home-page > .jxpres86xjf-editor_css {
  line-height: 1.5;
  color: #dddddd;
  margin: 20px 0px 0px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .home-page > .jxpres86xjf-editor_css {
    line-height: 1.5;
    width: 100%;
    margin: 20px 0px 0px;
  }
}
.ant-col > .content8-block > .jxpqrvgxh5b-editor_css {
  font-size: 12px;
  color: #888888;
  position: relative;
  top: -10px;
}
.ant-col > .content8-block > .jxpqrepr1ll-editor_css {
  font-size: 12px;
  color: #888888;
  position: relative;
  top: -10px;
}
div > .ant-row > .jxpsdo9pu08-editor_css {
  margin: 0px 0px 1px;
}
@media screen and (max-width: 767px) {
  div > .ant-row > .jxpsf0nmipn-editor_css {
    margin: 0 0 0px;
  }
}
.ant-col > .content8-block > .jxpqrc5l0d-editor_css {
  color: #353535;
  margin: 24px auto 0px;
}
@media screen and (max-width: 767px) {
  .ant-col > .content8-block > .jxpqrc5l0d-editor_css {
    color: #353535;
    text-align: left;
    margin: 24px auto 0px;
  }
}
.ant-col > .content8-block > .jxpqsbwmnns-editor_css {
  font-size: 12px;
  color: #888888;
  position: relative;
  top: -10px;
}
@media screen and (max-width: 767px) {
  .ant-col > .content8-block > .jxpqsbwmnns-editor_css {
    font-size: 12px;
    color: #888888;
    text-align: left;
    position: relative;
    top: -10px;
  }
}
.ant-col > .content8-block > .jxpqqc6z2qq-editor_css {
  color: #353535;
  margin: 12px auto 0px;
}
@media screen and (max-width: 767px) {
  .ant-col > .content8-block > .jxpqqc6z2qq-editor_css {
    color: #353535;
    text-align: left;
    margin: 10px auto 0px;
  }
}
.ant-col > .content8-block > .jxpqqcxgyzb-editor_css {
  font-size: 12px;
  color: #888888;
  position: relative;
  top: -10px;
}
@media screen and (max-width: 767px) {
  .ant-col > .content8-block > .jxpqqcxgyzb-editor_css {
    font-size: 12px;
    color: #888888;
    text-align: left;
    position: relative;
    margin: -10px 0 0;
  }
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxpsvdxqrs-editor_css {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  div > .ant-row > .jxpsepgc78q-editor_css {
    margin: -50px 0px 60px;
  }
}
@media screen and (max-width: 767px) {
  .home-page > .title-wrapper > .jxps9vgch2-editor_css {
    margin: -64px 0 30px;
  }
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .home-page > .jxpsbfb9wkm-editor_css {
    margin: 0px auto 64px;
  }
}
.home-page > .copyright > .jxpt49d5qhn-editor_css {
  color: rgba(153, 153, 153, 0);
}
div > .templates-wrapper > .jxpqyj4jcol-editor_css {
  height: 100%;
  min-height: 375px;
  background-attachment: scroll;
  box-shadow: none;
}
.home-page > .links > .jxpteiy5mw-editor_css {
  width: 180px;
  height: 180px;
  margin: 0px 32px 0px 0px;
}
.home-page > .links > .jxpteiy5mw-editor_css:hover {
  position: relative;
  background-image: url(https://pic.hereamazing.com/webpic/images/qrcode_for_gongzhonghao.png);
  background-size: 100%;
  margin: 0px 32px 0px 0px;
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jxpsvbubbdf-editor_css {
    height: 100%;
    min-height: 10px;
  }
}
.templates-wrapper > .home-page-wrapper > .jxppynokgb9-editor_css {
  min-height: 375px;
  margin: 80px auto auto;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .home-page-wrapper > .jxppynokgb9-editor_css {
    min-height: 375px;
    margin: 0px auto auto;
  }
}
.home-page-wrapper > .home-page > .jxpt4undvjl-editor_css {
  width: 100%;
  position: relative;
  padding: 0 0 0 46%;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .home-page > .jxpt4undvjl-editor_css {
    width: 1px;
    height: 1px;
    position: relative;
    padding: 0px 0px 0px 0%;
  }
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .home-page-wrapper > .jxpt6trvmf-editor_css {
    overflow: hidden;
    height: 0px;
  }
}
div > .templates-wrapper > .jxpt7c19n6-editor_css {
  height: 180px;
  background-color: rgba(0, 0, 0, 0);
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jxpt7c19n6-editor_css {
    height: 0;
  }
}
.home-page > .links > .jxpteagrq1i-editor_css {
  width: 180px;
  height: 180px;
  margin: 0px -30px 0px -100px;
}
.templates-wrapper > .home-page-wrapper > .jxpqhcaiwoe-editor_css {
  min-height: 375px;
  margin: 50px auto auto;
}
.header0-menu > .ant-menu > .jxpwp9rfdl6-editor_css {
  font-size: 16px;
  margin: -1px 0px 0px;
}
.header0-menu > .ant-menu > .jxpwp9rfdl6-editor_css:hover {
  border-bottom-color: rgba(0, 0, 0, 0);
  border-bottom-width: 0px;
  font-size: 16px;
  color: #00a29a;
  margin: -1px 0px 0px;
}
.header0-menu > .ant-menu > .jxoe1mydnng-editor_css {
  font-size: 16px;
  border-bottom-color: rgba(0, 0, 0, 0);
  margin: 0px 0px;
}
.header0-menu > .ant-menu > .jxoe1mydnng-editor_css:hover {
  margin: 0px 0px;
}
@media screen and (max-width: 767px) {
  .header0-menu > .ant-menu > .jxoe1mydnng-editor_css {
    background-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
  }
  .header0-menu > .ant-menu > .jxoe1mydnng-editor_css:active {
    background-color: #00a29a;
  }
}
.ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css:hover {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css:active {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css:focus {
  color: #00a29a;
}
@media screen and (max-width: 767px) {
  .ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css {
    font-size: 16px;
    text-align: center;
  }
}
.ant-menu > .ant-menu-item > .jxodp0is72e-editor_css {
  color: #ffffff;
}
.ant-menu > .ant-menu-item > .jxodp0is72e-editor_css:hover {
  font-size: 16px;
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxodp0is72e-editor_css:active {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxodp0is72e-editor_css:focus {
  color: #00a29a;
}
@media screen and (max-width: 767px) {
  .ant-menu > .ant-menu-item > .jxodp0is72e-editor_css {
    font-size: 16px;
    text-align: center;
    background-color: rgba(254, 0, 0, 0);
  }
}
.header0-menu > .ant-menu > .jxpwqu34bal-editor_css {
  font-size: 16px;
  border-bottom-width: 0px;
  margin: 0px 0px;
}
.header0-menu > .ant-menu > .jxpwqu34bal-editor_css:hover {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .header0-menu > .ant-menu > .jxpwqu34bal-editor_css {
    font-size: 16px;
    background-color: rgba(47, 84, 235, 0);
    border-bottom-width: 0px;
    margin: 0px 0px;
  }
}
.ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css {
  color: #ffffff;
}
.ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css:hover {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css:active {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css:focus {
  color: #00a29a;
}
@media screen and (max-width: 767px) {
  .ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css {
    font-size: 16px;
    text-align: center;
  }
}
.ant-col > .jxpxe3ttsx-editor_css > .jxpxdwnconu-editor_css {
  line-height: 43px;
  width: 150px;
  height: 45px;
}
.banner0 > .banner0-text-wrapper > .jxojes1ymrt-editor_css {
  line-height: 50px;
  text-decoration: none;
  width: 180px;
  height: 52px;
  margin-top: 80px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
}
.banner0 > .banner0-text-wrapper > .jxojes1ymrt-editor_css:hover {
  color: rgba(255, 255, 255, 0);
  width: 180px;
  height: 180px;
  box-shadow: none;
  background-image: url(https://pic.hereamazing.com/webpic/images/gh_e8504fcc38b2_12801.jpg);
}
.banner0 > .banner0-text-wrapper > .jxojes1ymrt-editor_css:focus {
  background-image: url(https://pic.hereamazing.com/webpic/images/immmm1.png);
  background-attachment: fixed;
}
@media screen and (max-width: 767px) {
  .banner0 > .banner0-text-wrapper > .jxojes1ymrt-editor_css {
    line-height: 50px;
    text-decoration: none;
    width: 180px;
    height: 52px;
    margin-top: 10vh;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}
.ant-col > div > .jxpxdwnconu-editor_css {
  line-height: 43px;
  color: #00a29a;
  width: 150px;
  height: 45px;
  border-color: #00a29a;
}
.ant-col > div > .jxpxdwnconu-editor_css:hover {
  color: rgba(89, 126, 247, 0);
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0);
  background-size: 100%;
  border-color: rgba(0, 0, 0, 0);
  -webkit-transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.ant-row > .ant-col > .jxppzxj6rsh-editor_css {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.5;
  color: #353535;
  margin: 52px auto 16px;
}
.ant-row > .ant-col > .jxpq0776zsq-editor_css {
  font-size: 14px;
  font-weight: 300;
  line-height: 2;
  color: #888888;
  margin: 30px 0px 0px;
}
.ant-row > .ant-col > .jxpxnj6z9id-editor_css {
  position: relative;
}
.ant-row > .ant-col > .jxpxnj6z9id-editor_css:hover {
  position: relative;
}
.ant-col > .jxpxnj6z9id-editor_css > .jxpxdwnconu-editor_css:hover {
  width: 150px;
  height: 150px;
}
.home-page-wrapper > .ant-row > .jxppykjno6r-editor_css {
  min-height: 350px;
  margin: 0 0 22px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: rgba(0, 21, 41, 0.95);
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  line-height: 62px;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: #fff;
}
.header0-menu > .ant-menu a:hover {
  color: #1890ff;
}
.header0 .ant-menu-item-selected a {
  color: #1890ff;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: #001529;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #fff;
  }
  .header0 .ant-menu a:hover {
    color: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #fff;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  -webkit-transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.parent2 {
  display: inline-block;
  border: 1px solid #ffffff;
  overflow: hidden;
  border-radius: 4px;
  padding: 0 5px;
  position: relative;
}
.qrcode {
  display: block;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 1px;
  left: 1px;
  object-fit: cover;
  z-index: 999;
  opacity: 0;
}
.left1 {
  width: 20px;
  height: 60px;
  float: left;
  margin-top: -2px;
  margin-left: 10px;
}
.left2 {
  width: 100px;
  height: 60px;
  float: left;
  margin-right: 20px;
}
.right2 {
  width: 20px;
  height: 60px;
  float: left;
  margin-top: -2px;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner2 .bg0 {
  background: url("https://zos.alipayobjects.com/rmsportal/bXNBIyzSLHaycUGDzwLG.jpg") center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-page {
  position: relative;
  top: 25%;
}
.banner2-text-wrapper {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  width: 400px;
  text-align: left;
}
.banner2-text-wrapper .banner2-title {
  left: 0;
  margin: auto;
  position: relative;
  font-size: 56px;
}
.banner2-text-wrapper .banner2-content {
  word-wrap: break-word;
  margin: auto auto 20px;
  font-weight: lighter;
}
.banner2-text-wrapper button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  -webkit-transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner2-text-wrapper button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  -webkit-transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 414px) {
  .banner2-text-wrapper {
    width: 90%;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-text-wrapper .logo {
    width: 90%;
    left: 0;
  }
}
.content13-wrapper {
  min-height: 380px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
.banner1 {
  width: 100%;
  height: 100vh;
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner1-wrapper,
.banner1 .banner-anim {
  height: 100%;
}
.banner1 .queue-anim-leaving {
  position: relative !important;
}
.banner1 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner1 .bg0 {
  background: url("https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg") center;
}
.banner1 .bg1 {
  background: url("https://zos.alipayobjects.com/rmsportal/xHxWkcvaIcuAdQl.jpg") center;
}
.banner1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner1 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner1 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner1 .banner1-text-wrapper {
  display: block;
  position: relative;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
  width: 550px;
  text-align: center;
}
.banner1 .banner1-title {
  width: 350px;
  left: 30px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner1 .banner1-content {
  margin-bottom: 20px;
  word-wrap: break-word;
}
.banner1 .banner1-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  -webkit-transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner1 .banner1-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner1 .banner1-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner1 .banner1-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner1 .banner1-button.queue-anim-leaving {
  width: auto;
}
.banner1 .banner1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .banner1 .banner1-text-wrapper {
    width: 90%;
  }
  .banner1 .banner1-text-wrapper .banner1-title {
    width: 90%;
    left: 0;
  }
  .banner1 .bg {
    background-attachment: inherit;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 1440px;
  }
}
.content6-wrapper {
  min-height: 720px;
}
.content6-wrapper .content6 {
  height: 100%;
  display: flex;
  align-items: center;
}
.content6-wrapper .content6-text {
  min-height: 424px;
}
.content6-wrapper .content6-text > *.queue-anim-leaving {
  position: relative !important;
}
.content6-wrapper .content6-text .title-h1 {
  position: relative;
  margin: 0 0 16px;
  text-align: left;
  font-size: 2em;
}
.content6-wrapper .content6-text .title-content {
  position: relative;
  margin-bottom: 64px;
  text-align: left;
}
.content6-wrapper .content6-text ul {
  position: relative;
  display: inline-block;
}
.content6-wrapper .content6-text ul li {
  margin-bottom: 24px;
}
.content6-wrapper .content6-text ul li .content6-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.content6-wrapper .content6-text ul li .content6-title,
.content6-wrapper .content6-text ul li .content6-content {
  margin-left: 45px;
}
.content6-wrapper .content6-text ul li .content6-title {
  font-size: 14px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .content6-wrapper {
    height: 860px;
    overflow: hidden;
  }
  .content6-wrapper .content6 {
    display: block;
  }
  .content6-wrapper .content6-img,
  .content6-wrapper .content6-text {
    display: block;
    width: 100%;
  }
  .content6-wrapper .content6-text > h1,
  .content6-wrapper .content6-text > p {
    text-align: center;
  }
  .content6-wrapper .content6-text > h1 {
    margin: 56px auto 16px;
  }
  .content6-wrapper .content6-text p {
    margin-bottom: 32px;
  }
  .content6-wrapper .content6-img {
    margin-top: 20px;
  }
}
.content11-wrapper {
  height: 480px;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 96px;
}
.content11-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content11-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background: -webkit-gradient(linear, left top, right top, from(#05cbff), to(#1e5aff)) !important;
  background: linear-gradient(to right, #05cbff, #1e5aff) !important;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #fff;
  width: 128px;
  padding: 0 15px;
  display: inline-block;
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
}
.content11-wrapper .button:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.content11-wrapper .button:active {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.content11-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content11-wrapper {
    padding-bottom: 0;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
.banner-anim > .banner-anim-elem > .jxr5vi1eob6-editor_css {
  width: 70%;
}
@media screen and (max-width: 767px) {
  div > .ant-row > .jxr66b5iu6-editor_css {
    overflow: hidden;
  }
}
.ant-row > .ant-col > .jxr65edby06-editor_css {
  width: 90%;
}
.ant-row > .ant-col > .jxr6jf3ollf-editor_css {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  text-align: left;
  width: 80%;
  max-height: 80px;
  min-height: 30px;
  position: fixed;
  top: 70%;
  left: 10%;
}
.ant-row > .ant-col > .jxr6ja9l7yo-editor_css {
  color: #ffffff;
  text-align: left;
  width: 80%;
  position: fixed;
  top: 59%;
  left: 10%;
}
.ant-row > .ant-col > .jxr671l5rbp-editor_css {
  color: #ffffff;
  text-align: left;
  width: 80%;
  position: fixed;
  top: 59%;
  left: 10%;
}
.ant-row > .ant-col > .jxr67212xt-editor_css {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  text-align: left;
  width: 80%;
  max-height: 80px;
  min-height: 30px;
  position: fixed;
  top: 70%;
  left: 10%;
}
.ant-row > .ant-col > .jxr75ry71n6-editor_css {
  color: #ffffff;
  text-align: left;
  width: 80%;
  position: fixed;
  top: 59%;
  left: 10%;
}
.ant-row > .ant-col > .jxr765zr9zr-editor_css {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  text-align: left;
  width: 80%;
  position: fixed;
  top: 70%;
  left: 10%;
}
.ant-row > .ant-col > .jxr670rnte-editor_css {
  font-size: 28px;
  font-weight: bold;
  color: #ffd700;
  text-align: left;
  width: 80%;
  position: fixed;
  top: 45%;
  left: 10%;
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxr670rnte-editor_css {
    width: 100%;
    position: fixed;
    top: 120px;
  }
}
.jxr7bksqazi-editor_css > .ant-row > .jxr7drbocn-editor_css {
  box-shadow: none;
}
.ant-row > .ant-col > .jxr657zaala-editor_css {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  overflow: hidden;
  width: 90%;
  border-radius: 4px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.ant-row > .ant-col > .jxr634vq4b-editor_css {
  overflow: hidden;
  width: 90%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.banner-anim > .banner-anim-elem > .jxr5pdqapzm-editor_css {
  background-attachment: scroll;
}
.banner-anim > .banner-anim-elem > .jxr5elt9u3-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/banner03-1.jpg);
}
.banner-anim > .banner-anim-elem > .jxr5kb10jj-editor_css {
  text-align: left;
  width: 90%;
  top: 30%;
  left: 0%;
}
.templates-wrapper > .home-page-wrapper > .jxr66gq8r6n-editor_css {
  padding: 64px 24px 4px;
}
.templates-wrapper > .home-page-wrapper > .jxr7b2uye3u-editor_css {
  padding: 64px 24px 128px;
}
.banner-anim-elem > .home-page > .jxr9e5vr9ms-editor_css {
  width: 80%;
  position: relative;
  left: 5%;
}
@media screen and (max-width: 767px) {
  .banner-anim-elem > .home-page > .jxr9e5vr9ms-editor_css {
    width: 100%;
    position: relative;
    left: 0%;
    margin: auto 0px;
  }
}
.home-page > .banner2-text-wrapper > .jxr9doeek2-editor_css {
  font-size: 50px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .home-page > .banner2-text-wrapper > .jxr9doeek2-editor_css {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
  }
}
.templates-wrapper > .home-page-wrapper > .jxr8dm3ccye-editor_css {
  height: 100%;
  position: static;
  bottom: 0px;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .home-page-wrapper > .jxr8dm3ccye-editor_css {
    height: 100%;
    position: relative;
    bottom: 50px;
  }
}
.templates-wrapper > .home-page-wrapper > .jxrk3peyqxh-editor_css {
  background-color: rgba(245, 0, 0, 0);
}
.home-page-wrapper > .jxrk3peyqxh-editor_css > .jxr7puwoyoj-editor_css {
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  width: 180px;
  height: 52px;
  background-color: #00a29a;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.banner-anim > .banner-anim-elem > .jxr87fgsc1t-editor_css {
  top: 5%;
}
@media screen and (max-width: 767px) {
  .banner-anim > .banner-anim-elem > .jxr87fgsc1t-editor_css {
    height: 90%;
    width: 100%;
    top: 10%;
  }
}
.home-page-wrapper > .title-wrapper > .jxr87986qxh-editor_css {
  font-size: 40px;
  color: #353535;
  bottom: 50px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .title-wrapper > .jxr87986qxh-editor_css {
    font-size: 30px;
    position: static;
    bottom: 0px;
  }
}
.ant-row > .ant-col > .jxr74ykrwd-editor_css {
  font-size: 28px;
  font-weight: bold;
  color: #fecb10;
  text-align: left;
  width: 80%;
  position: fixed;
  top: 45%;
  left: 10%;
}
.ant-row > .ant-col > .jxr6j5q889-editor_css {
  font-size: 28px;
  font-weight: bold;
  color: #fecb10;
  text-align: left;
  width: 80%;
  position: fixed;
  top: 45%;
  left: 10%;
}
ul > li > .jxrkg42ee29-editor_css {
  width: 50px;
  height: 50px;
}
.jxr9n65m5ah-editor_css > .banner-anim-elem > .jxr87fgsc1t-editor_css {
  top: 5%;
}
.ant-col > .title-wrapper > .jxrjmbba50d-editor_css {
  font-size: 40px;
  line-height: 1;
  color: #353535;
  margin: 0px 0px;
}
@media screen and (max-width: 767px) {
  .ant-col > .title-wrapper > .jxrjmbba50d-editor_css {
    font-size: 30px;
  }
}
.banner-anim-elem > .banner1-text-wrapper > .jxrl0ao38h-editor_css {
  font-weight: normal;
  margin: 15px 0px 20px;
}
.banner-anim > .banner-anim-elem > .jxr5ocubo9-editor_css {
  text-align: right;
  width: 80%;
  top: 30%;
}
.banner-anim-elem > .banner1-text-wrapper > .jxrl3hzzfak-editor_css {
  font-weight: normal;
  margin: 15px 0px 20px;
}
.banner-anim-elem > .banner1-text-wrapper > .jxr5k8b0bkp-editor_css {
  width: 100%;
  left: 0px;
}
@media screen and (max-width: 767px) {
  .banner-anim-elem > .banner1-text-wrapper > .jxr5k8b0bkp-editor_css {
    font-size: 30px;
    line-height: 1.2;
    width: 100%;
  }
}
.banner-anim-elem > .banner1-text-wrapper > .jxr5o50nwrk-editor_css {
  width: 100%;
  left: 0px;
}
@media screen and (max-width: 767px) {
  .banner-anim-elem > .banner1-text-wrapper > .jxr5o50nwrk-editor_css {
    font-size: 30px;
    line-height: 1.2;
    width: 100%;
  }
}
ul > li > .jxrlc13m8q7-editor_css {
  font-weight: 300;
  color: #888888;
}
ul > li > .jxrlcf942b8-editor_css {
  color: #353535;
}
ul > li > .jxrlcqfv8td-editor_css {
  color: #353535;
}
ul > li > .jxrlcx6uqfr-editor_css {
  color: #353535;
}
ul > li > .jxrlce66clq-editor_css {
  font-weight: 300;
  color: #888888;
}
ul > li > .jxrldeq7hd-editor_css {
  font-weight: 300;
  color: #888888;
}
.ant-col > .title-wrapper > .jxrkx0emjm-editor_css {
  font-weight: 300;
  color: #888888;
  margin: 15px 0px 64px;
}
.banner-anim > .banner-anim-elem > .jxr5p7gooh7-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/banner03-2.jpg);
}
.banner-anim > .banner-anim-elem > .jxr5rzcbcw6-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/banner03-3.jpg);
}
.home-page-wrapper > .title-wrapper > .jxrlfqbdzlr-editor_css {
  font-size: 16px;
  padding: 20px;
  font-weight: 300;
  color: #888888;
}
.home-page-wrapper > .title-wrapper > .jxrlfxif8vl-editor_css {
  font-weight: 300;
  color: #888888;
}
.banner-anim-elem > .banner1-text-wrapper > .jxrl5j911k-editor_css {
  font-weight: normal;
  border-top-width: -10px;
  margin-top: 10px;
}
.home-page > .title-wrapper > .jxr7lfzt088-editor_css {
  font-size: 40px;
  color: #353535;
  margin: 0px 0;
}
@media screen and (max-width: 767px) {
  .home-page > .title-wrapper > .jxr7lfzt088-editor_css {
    font-size: 30px;
  }
}
.home-page > div > .jxrlmywzjau-editor_css {
  margin: -20px 0px 0;
}
.banner0 > .banner0-text-wrapper > .jxr7wayr6hu-editor_css {
  font-size: 16px;
  font-weight: 300;
  position: relative;
  top: 30px;
}
@media screen and (max-width: 767px) {
  .banner0 > .banner0-text-wrapper > .jxr7wayr6hu-editor_css {
    font-size: 16px;
    font-weight: 300;
    position: relative;
    top: 50px;
  }
}
div > .templates-wrapper > .jxr875uwn3j-editor_css {
  max-height: 120px;
  min-height: 80px;
  background-image: none;
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jxr875uwn3j-editor_css {
    max-height: 130px;
    min-height: 80px;
    background-image: none;
  }
}
.banner0 > .banner0-text-wrapper > .jxr7xve73yp-editor_css {
  line-height: 50px;
  color: rgba(255, 255, 255, 0);
  width: 180px;
  height: 52px;
  top: 100px;
  border-width: 0px;
}
@media screen and (max-width: 767px) {
  .banner0 > .banner0-text-wrapper > .jxr7xve73yp-editor_css {
    line-height: 50px;
    color: rgba(255, 255, 255, 0);
    width: 180px;
    height: 52px;
    top: 100px;
    border-color: rgba(0, 0, 0, 0);
    border-width: 0px;
  }
}
.home-page > .banner2-text-wrapper > .jxrl2c51kuu-editor_css {
  line-height: 50px;
  color: rgba(255, 255, 255, 0);
  width: 180px;
  height: 52px;
  border-width: 0;
  margin: 20px 0px 0px;
}
@media screen and (max-width: 767px) {
  .home-page > .banner2-text-wrapper > .jxrl2c51kuu-editor_css {
    line-height: 50px;
    color: rgba(255, 255, 255, 0);
    width: 180px;
    height: 52px;
    border-color: rgba(0, 0, 0, 0);
    border-width: 0px;
    margin: 20px 0px 0px;
  }
}
.banner-anim-elem > .banner1-text-wrapper > .jxrl04uoi1l-editor_css {
  line-height: 50px;
  color: rgba(255, 255, 255, 0);
  width: 0px;
  height: 0px;
  border-width: 0px;
  margin: 80px 0px 0px;
}
@media screen and (max-width: 767px) {
  .banner-anim-elem > .banner1-text-wrapper > .jxrl04uoi1l-editor_css {
    line-height: 50px;
    color: rgba(255, 255, 255, 0.79);
    width: 0px;
    height: 0px;
    margin: 80px 0px 0px;
  }
}
.banner-anim-elem > .banner1-text-wrapper > .jxrl3h8n5b-editor_css {
  line-height: 50px;
  color: rgba(255, 255, 255, 0);
  width: 0px;
  height: 0px;
  border-width: 0;
  margin: 80px 0px 0px;
}
.templates-wrapper > .banner0 > .jxr7y5ap9y-editor_css {
  width: 100%;
  top: 25%;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .banner0 > .jxr7y5ap9y-editor_css {
    top: 22%;
  }
}
div > .templates-wrapper > .jxr7xg5gqf-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/3047535.jpg);
  background-attachment: fixed;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jxr7xg5gqf-editor_css {
    height: 90vh;
    background-image: url(https://pic.hereamazing.com/webpic/images/3047535-m.jpg);
    background-attachment: fixed;
    background-size: cover;
  }
}
.jxr9n65m5ah-editor_css > .banner-anim-elem > .jxr5pdqapzm-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/500733453.jpg);
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .jxr9n65m5ah-editor_css > .banner-anim-elem > .jxr5pdqapzm-editor_css {
    height: 90vh;
    background-image: url(https://pic.hereamazing.com/webpic/images/500733453.jpg);
    background-size: cover;
  }
}
div > .templates-wrapper > .jxr5f1e5ihq-editor_css {
  height: 80vh;
  max-width: 1200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jxr5f1e5ihq-editor_css {
    height: 80vh;
  }
}
.banner-anim-elem > .banner1-text-wrapper > .jxr5uvmr6sl-editor_css {
  width: 100%;
  left: 0px;
}
@media screen and (max-width: 767px) {
  .banner-anim-elem > .banner1-text-wrapper > .jxr5uvmr6sl-editor_css {
    font-size: 30px;
    line-height: 1.2;
    width: 100%;
  }
}
.banner-anim-elem > .banner1-text-wrapper > .jxr5vq4kmej-editor_css {
  line-height: 50px;
  width: 150px;
  height: 52px;
  margin: 80px 0px 0px;
}
@media screen and (max-width: 767px) {
  .banner-anim-elem > .banner1-text-wrapper > .jxr5vq4kmej-editor_css {
    line-height: 50px;
    width: 150px;
    height: 52px;
    border-color: #ffffff;
    margin: 70px 0px 0px;
  }
}
.banner0 > .banner0-text-wrapper > .jxr5c3zkfko-editor_css {
  font-size: 50px;
  font-weight: bold;
  width: 100%;
  max-width: 800px;
  top: 50px;
  left: 0px;
}
@media screen and (max-width: 767px) {
  .banner0 > .banner0-text-wrapper > .jxr5c3zkfko-editor_css {
    font-size: 30px;
    width: 90%;
    font-weight: bold;
    top: 50px;
  }
}
.header0-menu > .ant-menu > .jxsnclb8bh8-editor_css {
  border-width: 0px;
}
.header0-menu > .ant-menu > .jxsnclb8bh8-editor_css:hover {
  border-width: 0px;
  color: #00a29a;
}
.header0-menu > .ant-menu > .jxsndxju6n-editor_css {
  border-bottom-width: 0px;
}
.header0-menu > .ant-menu > .jxsne21oqe6-editor_css {
  color: #00a29a;
  border-bottom-width: 0px;
}
@media screen and (max-width: 767px) {
  .header0-menu > .ant-menu > .jxsnclb8bh8-editor_css {
    background-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
  }
  .header0-menu > .ant-menu > .jxsnclb8bh8-editor_css:active {
    background-color: none;
    color: #00a29a;
  }
}
div > .templates-wrapper > .jxsn7wu696-editor_css {
  position: absolute;
  background-color: rgba(0, 21, 41, 0);
  box-shadow: none;
}
.home-page > .banner2-text-wrapper > .jxr9elfhr1l-editor_css {
  font-weight: normal;
  position: relative;
  top: 20px;
  line-height: 2;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .home-page > .banner2-text-wrapper > .jxr9elfhr1l-editor_css {
    text-align: left;
  }
}
.header0 > .home-page > .jxsnhhexpc7-editor_css {
  width: 130px;
}
@media screen and (max-width: 767px) {
  .header0 > .home-page > .jxsnhhexpc7-editor_css {
    width: 80px;
  }
}
.ant-menu > .ant-menu-item > .jxsndoksajd-editor_css {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxsndoksajd-editor_css:hover {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxsndoksajd-editor_css:active {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxsndoksajd-editor_css:focus {
  font-size: 16px;
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxsnfud6b6-editor_css {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxsnfud6b6-editor_css:hover {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxsnfud6b6-editor_css:active {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxsnfud6b6-editor_css:focus {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxsnawo7u16-editor_css {
  font-size: 16px;
  color: #ffffff;
}
.ant-menu > .ant-menu-item > .jxsnawo7u16-editor_css:hover {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxsnawo7u16-editor_css:active {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxsnawo7u16-editor_css:focus {
  font-size: 16px;
  color: #00a29a;
}
@media screen and (max-width: 767px) {
  .ant-menu > .ant-menu-item > .jxsnawo7u16-editor_css {
    font-size: 16px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .header0 > .home-page > .jxpjefrz9io-editor_css {
    text-align: right;
    background-color: #f04134;
  }
  .header0 > .home-page > .jxpjefrz9io-editor_css:active {
    background-color: #ff0000;
  }
  .header0 > .home-page > .jxpjefrz9io-editor_css:focus {
    background-color: #ff0707;
  }
}
.header0-menu > .ant-menu > .jxsnd3mtzw7-editor_css {
  border-width: 0px;
}
.header0-menu > .ant-menu > .jxsnd3mtzw7-editor_css:hover {
  color: #00a29a;
  border-width: 0px;
}
.header0-menu > .ant-menu > .jxsnd3mtzw7-editor_css:focus {
  font-size: 16px;
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxsnd0nfut-editor_css {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxsnd0nfut-editor_css:hover {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxsnd0nfut-editor_css:active {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxsnd0nfut-editor_css:focus {
  font-size: 16px;
  color: #00a29a;
}
div > .templates-wrapper > .jxsnu4746w-editor_css {
  background-color: #00a29a;
}
.home-page-wrapper > .home-page > .jxsntxpl72-editor_css {
  border-top-width: 2;
  line-height: 1.5;
  color: #e7e7e7;
  position: relative;
  top: 25%;
}
.banner0 > .banner0-text-wrapper > .jxoc5sc064-editor_css {
  text-align: left;
  margin: 0px 0px 20px;
}
.templates-wrapper > .banner0 > .jxoc6mvv77g-editor_css {
  width: 750px;
}
.templates-wrapper > .banner0 > .jxojdzhi8bn-editor_css {
  width: 100%;
  height: 100%;
  top: 35%;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .banner0 > .jxojdzhi8bn-editor_css {
    width: 90%;
    height: 60vh;
    top: 30vh;
  }
}
.banner0 > .banner0-text-wrapper > .jxoc5sc064-editor_css {
  text-align: left;
  margin: 0px 0px 20px;
}
.templates-wrapper > .banner0 > .jxoc6mvv77g-editor_css {
  width: 750px;
}
.home-page-wrapper > .footer1 > .jxocs5m5n2-editor_css {
  cursor: null;
}
div > .templates-wrapper > .jxocvzr0pf-editor_css {
  color: #00a2ae;
}
.banner0 > .banner0-text-wrapper > .jxod7fbpcrf-editor_css {
  color: #f30e0e;
}
.ant-menu > .ant-menu-item > .jxocwaxbre-editor_css {
  font-weight: bold;
  color: #00bfbf;
  text-align: left;
  background-color: rgba(243, 0, 0, 0);
}
.ant-menu > .ant-menu-item > .jxocw9m2ykr-editor_css {
  color: #3f3f3f;
}
.templates-wrapper > .header0 > .jxoc9w9ljz7-editor_css {
  background-color: rgba(255, 255, 255, 0);
  background-repeat: repeat-x;
  background-position: center;
  background-size: 100%;
  background-attachment: fixed;
  margin: auto auto -100px;
}
div > .templates-wrapper > .jxocq9mh0e-editor_css {
  margin: 100px 0 0;
}
.templates-wrapper > .header0 > .jxodhly9z17-editor_css {
  color: rgba(49, 70, 89, 0);
  overflow: hidden;
  background-attachment: scroll;
}
div > .templates-wrapper > .jxodisnq1ai-editor_css {
  position: fixed;
  background-color: rgba(255, 255, 255, 0);
}
.home-page > .banner2-text-wrapper > .jxodvpkyru-editor_css {
  width: 200px;
  height: 50px;
  background-color: #00a2ae;
}
.banner-anim > .banner-anim-elem > .jxodll1yjcf-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/501102041.jpg);
}
div > .templates-wrapper > .jxodlvy1x9m-editor_css {
  color: rgba(255, 255, 255, 0);
  position: absolute;
  background-color: rgba(0, 21, 41, 0);
  box-shadow: none;
}
.banner-anim-elem > .home-page > .jxodw054rpn-editor_css {
  width: 50%;
  position: relative;
  left: 10%;
}
.home-page > .banner2-text-wrapper > .jxoeqdugc76-editor_css {
  position: relative;
}
.banner-anim > .banner-anim-elem > .jxodntepwcp-editor_css {
  top: auto;
}
.home-page > .banner2-text-wrapper > .jxodprz6epo-editor_css {
  font-size: 50px;
  line-height: 1em;
  background-attachment: fixed;
}
.home-page > .banner2-text-wrapper > .jxodprz6epo-editor_css:hover {
  color: #108ee9;
}
@media screen and (max-width: 767px) {
  .home-page > .banner2-text-wrapper > .jxodprz6epo-editor_css {
    line-height: 1em;
    width: 100%;
    position: static;
    background-attachment: fixed;
  }
}
.banner3 > .banner3-text-wrapper > .jxof3fcg82s-editor_css {
  font-weight: lighter;
}
div > .templates-wrapper > .jxof2guvi96-editor_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/501102041.jpg);
}
.banner3 > .banner3-text-wrapper > .jxof1vkoxr-editor_css {
  font-weight: 400;
  background-attachment: scroll;
}
@media screen and (max-width: 767px) {
  .banner3 > .banner3-text-wrapper > .jxof1vkoxr-editor_css {
    font-size: 26px;
    font-weight: bold;
  }
}
.templates-wrapper > .banner3 > .jxof59d06lb-editor_css {
  height: 460px;
  top: 30%;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .banner3 > .jxof59d06lb-editor_css {
    width: 95%;
    height: 460px;
    position: relative;
    top: 30%;
  }
}
.header0 > .home-page > .jxodljf52sb-editor_css {
  width: 130px;
}
@media screen and (max-width: 767px) {
  .header0 > .home-page > .jxodljf52sb-editor_css {
    width: 80px;
  }
}
.ant-row > .ant-col > .jxofl1suvja-editor_css {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxofl1suvja-editor_css {
    width: 100%;
    height: 240px;
    position: relative;
  }
}
div > .templates-wrapper > .jxog00tng6-editor_css {
  height: 520px;
}
.home-page-wrapper > .ant-row > .jxofu4u0l4c-editor_css {
  height: 450px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .ant-row > .jxofu4u0l4c-editor_css {
    height: 250px;
    margin: 0px 0px;
  }
}
.home-page-wrapper > .ant-row > .jxofl0jryef-editor_css {
  padding: 0px 32px 0px 0px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .ant-row > .jxofl0jryef-editor_css {
    padding: 0px 0px;
  }
}
.ant-row > .ant-col > .jxog0hehvin-editor_css {
  font-size: 36px;
  font-weight: bold;
  line-height: 2;
  color: #353535;
  min-width: 1px;
  margin: 80px 0px 0.5em;
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxog0hehvin-editor_css {
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    color: #353535;
    min-width: 1px;
    margin: 10px 0px 0.5em;
  }
}
.templates-wrapper > .home-page-wrapper > .jxofw3q75w-editor_css {
  line-height: 2em;
  height: 450px;
  margin: 80px auto auto;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .home-page-wrapper > .jxofw3q75w-editor_css {
    line-height: 2em;
    height: 750px;
    margin: 20px auto auto;
  }
}
.ant-row > .ant-col > .jxog0wahfrn-editor_css {
  font-size: 16px;
  line-height: 2;
  color: #353535;
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxog0wahfrn-editor_css {
    font-size: 16px;
    font-weight: lighter;
    line-height: 1.5;
    color: #353535;
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .header0 > .home-page > .jxpjefrz9io-editor_css {
    text-align: right;
    background-color: #f04134;
  }
  .header0 > .home-page > .jxpjefrz9io-editor_css:active {
    background-color: #ff0000;
  }
  .header0 > .home-page > .jxpjefrz9io-editor_css:focus {
    background-color: #ff0707;
  }
}
.ant-col > .jxpklu9drag-editor_css > .jxpklhxf47k-editor_css {
  line-height: 38px;
  height: 40px;
}
.ant-col > div > .jxpklhxf47k-editor_css {
  line-height: 43px;
  color: #00a29a;
  width: 150px;
  height: 45px;
  border-color: #00a29a;
}
.ant-row > .ant-col > .jxpjevgd316-editor_css {
  margin: 0 0 10px;
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxpjevgd316-editor_css {
    width: 100%;
    margin: 0px 0px 10px;
  }
}
.templates-wrapper > .home-page-wrapper > .jxpkgua6sq-editor_css {
  min-height: 375px;
  margin: 80px auto auto;
  padding: 0px 24px 10px;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .home-page-wrapper > .jxpkgua6sq-editor_css {
    min-height: 375px;
    margin: 30px auto auto;
    padding: 0px 24px 10px;
  }
}
.ant-col > .jxpkunzotz9-editor_css > .jxpklhxf47k-editor_css {
  color: #ffffff;
  background-color: #00a29a;
}
.templates-wrapper > .banner0 > .jxojdzhi8bn-editor_css {
  width: 100%;
  height: 100%;
  top: 35%;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .banner0 > .jxojdzhi8bn-editor_css {
    width: 100%;
    height: 60vh;
    top: 30vh;
  }
}
div > .templates-wrapper > .jxojc16h1z-editor1_css {
  background-image: url(https://pic.hereamazing.com/webpic/images/hostbanner01.jpg);
  background-attachment: scroll;
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jxojc16h1z-editor1_css {
    height: 90vh;
    background-image: url(https://pic.hereamazing.com/webpic/images/hostbanner01-m.jpg);
  }
}
.banner0 > .banner0-text-wrapper > .jxoje9sxbkp-editor_css {
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  padding: 0px 5px;
}
@media screen and (max-width: 767px) {
  .banner0 > .banner0-text-wrapper > .jxoje9sxbkp-editor_css {
    font-size: 16px;
    font-weight: 300;
    padding: 0px 5px;
    margin-top: 10px;
  }
}
.banner0 > .banner0-text-wrapper > .jxojbfnhvy-editor1_css {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.5;
  width: 90%;
  left: 0px;
  -webkit-transition: all 1000ms ease 0s;
  transition: all 1000ms ease 0s;
}
@media screen and (max-width: 767px) {
  .banner0 > .banner0-text-wrapper > .jxojbfnhvy-editor1_css {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5;
    width: 90%;
    min-height: 30px;
    -webkit-transition: all 1000ms ease 0s;
    transition: all 1000ms ease 0s;
  }
}
.ant-row > .ant-col > .jxpkgrktuy-editor_css {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.5;
  color: #353535;
  margin: 52px auto 16px;
}
.ant-row > .ant-col > .jxpkht4ady-editor_css {
  font-size: 14px;
  font-weight: 300;
  line-height: 2;
  color: #888888;
}
.ant-row > .ant-col > .jxpo8so15v-editor_css {
  font-weight: 300;
  line-height: 2;
  color: #888888;
}
div > .templates-wrapper > .jxpoawea7o-editor_css {
  height: 100%;
  min-height: 375px;
}
.ant-row > .ant-col > .jxpo9abxym-editor_css {
  font-size: 36px;
  font-weight: bold;
  color: #353535;
  margin: 80px 0 0.5em;
}
.ant-row > .ant-col > .jxpoa06cgyd-editor_css {
  max-width: 560px;
  height: 12px;
  position: static;
}
.templates-wrapper > .home-page-wrapper > .jxpo5qj1bou-editor_css {
  height: 100%10;
  min-height: 375px;
}
.home-page-wrapper > .ant-row > .jxpkhhbixs-editor_css {
  padding: 0px 0px;
}
.home-page-wrapper > .ant-row > .jxpootbvhl-editor_css {
  max-width: 560px;
}
.home-page-wrapper > .ant-row > .jxpoovyttw-editor_css {
  max-width: 560px;
  padding: 0px 0px;
}
.ant-row > .ant-col > .jxpooo0bxec-editor_css {
  width: 100%;
  max-width: 560px;
}
div > .templates-wrapper > .jxpori14qfg-editor_css {
  margin: 50px 0px 0px;
}
.templates-wrapper > .home-page-wrapper > .jxpouhtsxis-editor_css {
  width: 100%;
  min-height: 375px;
}
.ant-row > .ant-col > .jxpp58jsysc-editor_css {
  width: 100%;
  max-width: 640px;
  position: relative;
}
.ant-row > .ant-col > .jxppeera6pq-editor_css {
  font-size: 36px;
  font-weight: bold;
  color: #353535;
}
.ant-row > .ant-col > .jxpperqlmk-editor_css {
  font-weight: 300;
  line-height: 2;
  color: #888888;
}
.home-page-wrapper > .ant-row > .jxpp5799ece-editor_css {
  max-width: 600px;
  padding: 0px 0px;
}
.home-page-wrapper > .ant-row > .jxpp55u5xrb-editor_css {
  max-width: 640px;
  left: 24px;
  padding: 0px 0px;
}
.templates-wrapper > .home-page-wrapper > .jxpp8swgtgl-editor_css {
  min-height: 375px;
}
div > .templates-wrapper > .jxppcc8nn-editor_css {
  height: 100%;
  min-height: 375px;
  top: 50px;
}
.home-page-wrapper > .ant-row > .jxppyccntmm-editor_css {
  padding: 0px 0px;
}
.ant-row > .ant-col > .jxpq0fojbz-editor_css {
  color: rgba(64, 64, 64, 0);
  margin: 0px 0px;
}
.ant-row > .ant-col > .jxpkgd4pbuj-editor_css {
  color: rgba(255, 255, 255, 0);
  margin: 0px 0px;
}
.home-page-wrapper > .ant-row > .jxpkpyk5ie9-editor_css {
  min-height: 350px;
}
.ant-col > .content5-block-content > .jxpqaknkbdf-editor_css {
  background-color: rgba(233, 233, 233, 0);
  background-attachment: scroll;
}
.ant-col > .content8-block > .jxpqqfcy16e-editor_css {
  color: #353535;
  margin: 12px auto 8px;
}
.ant-col > .content8-block > .jxpqqp6573c-editor_css {
  color: #353535;
  margin: 24px auto 0px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .home-page > .jxpqz5l3w6d-editor_css {
    position: relative;
    left: 30px;
  }
}
.ant-row > .ant-col > .jxpr9rrlbma-editor_css {
  width: 120px;
}
.home-page-wrapper > .footer1 > .jxprccpimcb-editor_css {
  text-align: left;
}
.templates-wrapper > .home-page-wrapper > .jxprenmq9y-editor_css {
  line-height: 50px;
  height: 80px;
}
div > .templates-wrapper > .jxprn4m83k-editor_css {
  background-color: #00a29a;
}
.home-page-wrapper > .home-page > .jxpres86xjf-editor_css {
  line-height: 1.5;
  color: #dddddd;
  margin: 20px 0px 0px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .home-page > .jxpres86xjf-editor_css {
    line-height: 1.5;
    width: 100%;
    margin: 20px 0px 0px;
  }
}
.ant-col > .content8-block > .jxpqrvgxh5b-editor_css {
  font-size: 12px;
  color: #888888;
  position: relative;
  top: -10px;
}
.ant-col > .content8-block > .jxpqrepr1ll-editor_css {
  font-size: 12px;
  color: #888888;
  position: relative;
  top: -10px;
}
div > .ant-row > .jxpsdo9pu08-editor_css {
  margin: 0px 0px 1px;
}
@media screen and (max-width: 767px) {
  div > .ant-row > .jxpsf0nmipn-editor_css {
    margin: 0 0 0px;
  }
}
.ant-col > .content8-block > .jxpqrc5l0d-editor_css {
  color: #353535;
  margin: 24px auto 0px;
}
@media screen and (max-width: 767px) {
  .ant-col > .content8-block > .jxpqrc5l0d-editor_css {
    color: #353535;
    text-align: left;
    margin: 24px auto 0px;
  }
}
.ant-col > .content8-block > .jxpqsbwmnns-editor_css {
  font-size: 12px;
  color: #888888;
  position: relative;
  top: -10px;
}
@media screen and (max-width: 767px) {
  .ant-col > .content8-block > .jxpqsbwmnns-editor_css {
    font-size: 12px;
    color: #888888;
    text-align: left;
    position: relative;
    top: -10px;
  }
}
.ant-col > .content8-block > .jxpqqc6z2qq-editor_css {
  color: #353535;
  margin: 12px auto 0px;
}
@media screen and (max-width: 767px) {
  .ant-col > .content8-block > .jxpqqc6z2qq-editor_css {
    color: #353535;
    text-align: left;
    margin: 10px auto 0px;
  }
}
.ant-col > .content8-block > .jxpqqcxgyzb-editor_css {
  font-size: 12px;
  color: #888888;
  position: relative;
  top: -10px;
}
@media screen and (max-width: 767px) {
  .ant-col > .content8-block > .jxpqqcxgyzb-editor_css {
    font-size: 12px;
    color: #888888;
    text-align: left;
    position: relative;
    margin: -10px 0 0;
  }
}
@media screen and (max-width: 767px) {
  .ant-row > .ant-col > .jxpsvdxqrs-editor_css {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  div > .ant-row > .jxpsepgc78q-editor_css {
    margin: -50px 0px 60px;
  }
}
@media screen and (max-width: 767px) {
  .home-page > .title-wrapper > .jxps9vgch2-editor_css {
    margin: px 0 26px;
  }
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .home-page > .jxpsbfb9wkm-editor_css {
    margin: 0px auto 64px;
  }
}
.home-page > .copyright > .jxpt49d5qhn-editor_css {
  color: rgba(153, 153, 153, 0);
}
div > .templates-wrapper > .jxpqyj4jcol-editor_css {
  height: 100%;
  min-height: 375px;
  background-attachment: scroll;
  box-shadow: none;
}
.home-page > .links > .jxpteiy5mw-editor_css {
  width: 180px;
  height: 180px;
  margin: 0px 32px 0px 0px;
}
.home-page > .links > .jxpteiy5mw-editor_css:hover {
  position: relative;
  background-image: url(https://pic.hereamazing.com/webpic/images/qrcode_for_gongzhonghao.png);
  background-size: 100%;
  margin: 0px 32px 0px 0px;
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jxpsvbubbdf-editor_css {
    height: 100%;
    min-height: 10px;
  }
}
.templates-wrapper > .home-page-wrapper > .jxppynokgb9-editor_css {
  min-height: 375px;
  margin: 80px auto auto;
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .home-page-wrapper > .jxppynokgb9-editor_css {
    min-height: 375px;
    margin: 0px auto auto;
  }
}
.home-page-wrapper > .home-page > .jxpt4undvjl-editor_css {
  width: 100%;
  position: relative;
  padding: 0 0 0 46%;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper > .home-page > .jxpt4undvjl-editor_css {
    width: 1px;
    height: 1px;
    position: relative;
    padding: 0px 0px 0px 0%;
  }
}
@media screen and (max-width: 767px) {
  .templates-wrapper > .home-page-wrapper > .jxpt6trvmf-editor_css {
    overflow: hidden;
    height: 0px;
  }
}
div > .templates-wrapper > .jxpt7c19n6-editor_css {
  height: 180px;
  background-color: rgba(0, 0, 0, 0);
}
@media screen and (max-width: 767px) {
  div > .templates-wrapper > .jxpt7c19n6-editor_css {
    height: 0;
  }
}
.home-page > .links > .jxpteagrq1i-editor_css {
  width: 180px;
  height: 180px;
  margin: 0px -30px 0px -100px;
}
.templates-wrapper > .home-page-wrapper > .jxpqhcaiwoe-editor_css {
  min-height: 375px;
  margin: 70px auto auto;
}
.header0-menu > .ant-menu > .jxpwp9rfdl6-editor_css {
  font-size: 16px;
  margin: -1px 0px 0px;
}
.header0-menu > .ant-menu > .jxpwp9rfdl6-editor_css:hover {
  border-bottom-color: rgba(0, 0, 0, 0);
  border-bottom-width: 0px;
  font-size: 16px;
  color: #00a29a;
  margin: -1px 0px 0px;
}
.header0-menu > .ant-menu > .jxoe1mydnng-editor_css {
  font-size: 16px;
  border-bottom-color: rgba(0, 0, 0, 0);
  margin: 0px 0px;
}
.header0-menu > .ant-menu > .jxoe1mydnng-editor_css:hover {
  margin: 0px 0px;
}
@media screen and (max-width: 767px) {
  .header0-menu > .ant-menu > .jxoe1mydnng-editor_css {
    background-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
  }
  .header0-menu > .ant-menu > .jxoe1mydnng-editor_css:active {
    background-color: #00a29a;
  }
}
.ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css:hover {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css:active {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css:focus {
  color: #00a29a;
}
@media screen and (max-width: 767px) {
  .ant-menu > .ant-menu-item > .jxoe7hvjxm-editor_css {
    font-size: 16px;
    text-align: center;
  }
}
.ant-menu > .ant-menu-item > .jxodp0is72e-editor_css {
  color: #ffffff;
}
.ant-menu > .ant-menu-item > .jxodp0is72e-editor_css:hover {
  font-size: 16px;
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxodp0is72e-editor_css:active {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxodp0is72e-editor_css:focus {
  color: #00a29a;
}
@media screen and (max-width: 767px) {
  .ant-menu > .ant-menu-item > .jxodp0is72e-editor_css {
    font-size: 16px;
    text-align: center;
    background-color: rgba(254, 0, 0, 0);
  }
}
.header0-menu > .ant-menu > .jxpwqu34bal-editor_css {
  font-size: 16px;
  border-bottom-width: 0px;
  margin: 0px 0px;
}
.header0-menu > .ant-menu > .jxpwqu34bal-editor_css:hover {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .header0-menu > .ant-menu > .jxpwqu34bal-editor_css {
    font-size: 16px;
    background-color: rgba(47, 84, 235, 0);
    border-bottom-width: 0px;
    margin: 0px 0px;
  }
}
.ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css {
  color: #ffffff;
}
.ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css:hover {
  color: #00a29a;
}
.ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css:active {
  font-size: 16px;
}
.ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css:focus {
  color: #00a29a;
}
@media screen and (max-width: 767px) {
  .ant-menu > .ant-menu-item > .jxoe840fy9d-editor_css {
    font-size: 16px;
    text-align: center;
  }
}
.ant-col > .jxpxe3ttsx-editor_css > .jxpxdwnconu-editor_css {
  line-height: 43px;
  width: 150px;
  height: 45px;
}
.banner0 > .banner0-text-wrapper > .jxojes1ymrt-editor_css {
  line-height: 50px;
  text-decoration: none;
  width: 180px;
  height: 52px;
  margin-top: 80px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
}
.banner0 > .banner0-text-wrapper > .jxojes1ymrt-editor_css:hover {
  color: rgba(255, 255, 255, 0);
  width: 180px;
  height: 180px;
  background-size: cover;
}
.banner0 > .banner0-text-wrapper > .jxojes1ymrt-editor_css:focus {
  background-image: url(https://pic.hereamazing.com/webpic/images/immmm1.png);
  background-attachment: fixed;
}
@media screen and (max-width: 767px) {
  .banner0 > .banner0-text-wrapper > .jxojes1ymrt-editor_css {
    line-height: 50px;
    text-decoration: none;
    width: 180px;
    height: 52px;
    margin-top: 10vh;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}
.ant-col > div > .jxpxdwnconu-editor_css {
  line-height: 43px;
  color: #00a29a;
  width: 150px;
  height: 45px;
  border-color: #00a29a;
}
.ant-row > .ant-col > .jxppzxj6rsh-editor_css {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.5;
  color: #353535;
  margin: 52px auto 16px;
}
.ant-row > .ant-col > .jxpq0776zsq-editor_css {
  font-size: 14px;
  font-weight: 300;
  line-height: 2;
  color: #888888;
  margin: 30px 0px 0px;
}
.ant-row > .ant-col > .jxpxnj6z9id-editor_css {
  position: relative;
}
.ant-row > .ant-col > .jxpxnj6z9id-editor_css:hover {
  position: relative;
}
.home-page-wrapper > .ant-row > .jxppykjno6r-editor_css {
  min-height: 350px;
  margin: 0 0 22px;
}

